import background from "./bg.png";
import bottomBackground from "./bgdown.svg";
import NFO from "./nfo.svg";
import NFOLogo from "./NFOLogo.svg";
import NFOLogo2x from "./nfologo@2x.png";
import NFOLogoBig from "./NFOLogoBig.svg";
import GuidebookPreview1 from "./advn.png";
import GuidebookPreview2 from "./Guide-Img.png";
import WorkbookPreview3 from "./Workbook-img.png";
import PhoneIcon from "./phone-icon.png";
import TitleLine from "./titleline.svg";
import NatGuidebookPrev from "./NationalGuidebookPreview.png";
import group11917 from "./group-11917.png";
import image21 from "./image-21.svg";
import backArrowImage from "./backArrowImage.svg";
import cancelPolicyImage from "./cancelPolicyImage.png";
import clock from "./clock.svg";
import std6_guidebook from "./std6_guidebook.png";
import std7_guidebook from "./std7_guidebook.png";
import std8_guidebook from "./std8_guidebook.png";
import std9_guidebook from "./std9_guidebook.png";
import shelfTitleRect from "./rectangle-3701.svg";
import shelfDepthRect from "./rectangle-3697.svg";
import shelfFrontRect from "./vector-1.svg";
import std7_slanting_book from "./std7_slanting_book.png";
import mockQuizLaptop from "./mockQuizLaptop.svg";
import topArtworkBg from "./topArtworkBg.svg";
import bottomArtworkBg from "./bottomArtworkBg.svg";
import userProfileVector from "./userProfileVector.svg";
import arrowLeftWhite from "./arrow-left-white.svg";
import backArrowWithoutFill from "./backArrowWithoutFill.svg";
import arrowLeftBlue from "./arrow-left-blue.svg";
import refreshCcw from "./refresh-cw.svg";
import loaderIcon from "./loader.svg";
import teacherIcon from "./type.svg";
import addTeacher from "./add-teacher.svg";
import downloadTeacher from "./teacher-download.svg";


export {
  background,
  bottomBackground,
  NFO,
  NFOLogo,
  NFOLogo2x,
  NFOLogoBig,
  GuidebookPreview1,
  GuidebookPreview2,
  WorkbookPreview3,
  PhoneIcon,
  TitleLine,
  NatGuidebookPrev,
  group11917,
  image21,
  backArrowImage,
  cancelPolicyImage,
  clock,
  std6_guidebook,
  std7_guidebook,
  std8_guidebook,
  std9_guidebook,
  shelfTitleRect,
  shelfDepthRect,
  shelfFrontRect,
  std7_slanting_book,
  mockQuizLaptop,
  topArtworkBg,
  bottomArtworkBg,
  userProfileVector,
  arrowLeftWhite,
  backArrowWithoutFill,
  arrowLeftBlue,
  refreshCcw,
  loaderIcon as loadingIcon,
  teacherIcon,
  addTeacher,
  downloadTeacher,
};
