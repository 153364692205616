// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.log-all-out {
    cursor: pointer;
}

.logo-icon-invalid {
   position: relative !important;
   left: auto !important;
   margin: 40px;
   width: auto;
   height: 120px;
}`, "",{"version":3,"sources":["webpack://./src/InvalidSession/InvalidSession.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;GACG,6BAA6B;GAC7B,qBAAqB;GACrB,YAAY;GACZ,WAAW;GACX,aAAa;AAChB","sourcesContent":[".log-all-out {\n    cursor: pointer;\n}\n\n.logo-icon-invalid {\n   position: relative !important;\n   left: auto !important;\n   margin: 40px;\n   width: auto;\n   height: 120px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
